import Vue from "vue"

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/app.scss";

import App from "./App.vue"
import router from "./router"
import {BootstrapVue, BootstrapVueIcons} from "bootstrap-vue"
import axios from "axios";
import VueAxios from "vue-axios";
import auth from "@websanova/vue-auth/src/v2.js";
import driverAuthBearer from "@websanova/vue-auth/src/drivers/auth/bearer.js";
import AuthHttpDriver from "./AuthHttpDriver";
import driverRouterVueRouter from "@websanova/vue-auth/src/drivers/router/vue-router.2.x.js";
import Alertify from "./Alertify";
import JsonRpc from "./JsonRpc";
import FormValidation from "./FormValidation";
import "./components/modal-druggable/modal-win-component.min"

/** @property tz */
const moment = require("moment-timezone");

const brands = {
    krediska: "Кредиска",
    beriberu: "Бери Беру",
    hurmacredit: "Хурма Кредит",
    davaka: "Да Кредит",
}

Vue.use(VueAxios, axios);
Vue.use(JsonRpc);
Vue.use(Alertify);
Vue.use(FormValidation);
Vue.use({
    install(Vue) {
        Vue.prototype.$brand = () => {
            return {
                label: brands[window.env["BRAND_NAME"]],
                value: window.env["BRAND_NAME"]
            }
        }
    }
})

Vue.use(auth, {
    plugins: {
        http: Vue.axios,
        router: router,
    },
    drivers: {
        auth: driverAuthBearer,
        http: AuthHttpDriver,
        router: driverRouterVueRouter,
    },
    options: {
        authRedirect: "/login",
        rolesKey: "roles",
        notFoundRedirect: {name: "user-account"},
        loginData: {
            url: "/api/v1",
            jsonRpcMethod: "auth.login",
            fetchUser: false,
        },
        refreshData: {
            jsonRpcMethod: "auth.refresh",
            interval: 10, // minutes
        },
        fetchData: {
            jsonRpcMethod: "auth.profile",
        }
    }
});

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false;

Vue.component("Poster", require("./components/Poster.vue").default);

const formatDatetime = window.formatDatetime = function (
    value,
    format = "DD.MM.YYYY HH:mm:ss",
    timezone = "Europe/Moscow",
) {
    if (typeof value === "string" || typeof value === "number") {
        return moment.tz(value, timezone).format(format);
    }

    throw "Invalid dateTime value";
};

const numberFormat = function( number, decimals, dec_point, thousands_sep ) {
    var i, j, kw, kd, km;
    if( isNaN(decimals = Math.abs(decimals)) ){
        decimals = 2;
    }
    if( dec_point == undefined ){
        dec_point = ",";
    }
    if( thousands_sep == undefined ){
        thousands_sep = ".";
    }
    i = parseInt(number = (+number || 0).toFixed(decimals)) + "";
    if( (j = i.length) > 3 ){
        j = j % 3;
    } else{
        j = 0;
    }
    km = (j ? i.substr(0, j) + thousands_sep : "");
    kw = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands_sep);
    kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : "");

    return km + kw + kd;
}

Vue.filter("formatDatetime", formatDatetime);
Vue.filter("numberFormat", numberFormat);

window.app = new Vue({
    router,
    render: h => h(App),
}).$mount("#app")
